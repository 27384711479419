import request from '@/utils/request'
const baseURL = '/baseapi'
const roleApi = {
  GridRoleList: '/admin/gridrole/list',
  SetGridRoleStatus: '/admin/gridrole/setRoleStatus',
  GridAddRole: '/admin/gridrole/addRole',
  Condition: '/admin/gridrole/condition',
  GridRoleInfo: '/admin/gridrole/gridRoleInfo',
  UpdateFrontPermission: '/admin/gridrole/updateFrontPermission',
  SaveRegionPower: '/admin/gridrole/saveRegionPower'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function gridRoleList (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.GridRoleList,
    method: 'post',
    data: parameter
  })
}

export function setGridRoleStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.SetGridRoleStatus,
    method: 'post',
    data: parameter
  })
}

export function gridRoleInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.GridRoleInfo,
    method: 'post',
    data: parameter
  })
}

export function gridAddRole (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.GridAddRole,
    method: 'post',
    data: parameter
  })
}

export function gridRoleCondition (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.Condition,
    method: 'post',
    data: parameter
  })
}

export function updateFrontPermission (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.UpdateFrontPermission,
    method: 'post',
    data: parameter
  })
}

export function saveRegionPower (parameter) {
  return request({
    baseURL: baseURL,
    url: roleApi.SaveRegionPower,
    method: 'post',
    data: parameter
  })
}
