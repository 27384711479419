import request from '@/utils/request'
const baseURL = '/baseapi'
const gridhouseApi = {
  GridCommunityList: '/admin/gridhouse/communityList',
  GridBuildingList: '/admin/gridhouse/buildingList',
  GridUnitList: '/admin/gridhouse/unitList',
  GridHouseList: '/admin/gridhouse/houseList',
  GridCheckParent: '/admin/gridhouse/checkParent',
  GridRangeList: '/admin/gridhouse/rangeList'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function gridCommunityList () {
  return request({
    baseURL: baseURL,
    url: gridhouseApi.GridCommunityList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function gridBuildingList (parameter) {
  return request({
    baseURL: baseURL,
    url: gridhouseApi.GridBuildingList,
    method: 'post',
    data: parameter
  })
}

export function gridUnitList (parameter) {
  return request({
    baseURL: baseURL,
    url: gridhouseApi.GridUnitList,
    method: 'post',
    data: parameter
  })
}

export function gridHouseList (parameter) {
  return request({
    baseURL: baseURL,
    url: gridhouseApi.GridHouseList,
    method: 'post',
    data: parameter
  })
}

export function gridCheckParent (parameter) {
  return request({
    baseURL: baseURL,
    url: gridhouseApi.GridCheckParent,
    method: 'post',
    data: parameter
  })
}

export function gridRangeList () {
  return request({
    baseURL: baseURL,
    url: gridhouseApi.GridRangeList,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
